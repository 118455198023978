import React, { FunctionComponent, useContext } from "react";
import { Link } from "../Link";
import "./styles.scss";

import sanityImageUrlBuilder from "web-common/src/utils/imageUrlBuilder";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import { LocalizedContext } from "web-common/src/services/LocalizedContextService";
import { ImageInterface } from "../../types/SanityTypes";

export interface LinktreeItemInterface {
  label: string
  linkURL: string
  icon: ImageInterface
}

export interface LinktreeInterface {
  title: string
  subtitle: string
  logo: ImageInterface
  links: LinktreeItemInterface[];
}

const Linktree: FunctionComponent<LinktreeInterface> = ({ title, subtitle, logo, links }) => {
  const { pageLanguage } = useContext(LocalizedContext);
  const { sanityId, sanityDataset } = useSiteMetadata(pageLanguage);

  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  return (
    <section className="link-tree">
      <header className="link-tree__header">
        {logo && (
          <img
            className="link__logo"
            src={urlBuilder
              .image(logo)
              .auto("format")
              .quality(80)
              .width(256)
              .height(256)
              .fit("max")
              .format("webp")
              .url()}
            loading={"lazy"}
            alt={logo.alt}
          />
        )}

        {title && <div className="link-tree__title rx-gradient--red-pink">{title}</div>}
        {subtitle && <div className="link-tree__subtitle">{subtitle}</div>}
      </header>

      {links.map(({ label, linkURL, icon }) => (
        <Link key={label} to={linkURL} className="link-tree__link" external={linkURL.includes("http")}>
          <div className="btn-primary">
            {icon && (
              <img
                className="link__image"
                src={urlBuilder
                  .image(icon)
                  .auto("format")
                  .quality(80)
                  .width(40)
                  .height(40)
                  .fit("max")
                  .format("webp")
                  .url()}
                loading={"lazy"}
                alt={icon.alt}
              />
            )}
            <div className="link__label">{label}</div>
          </div>
        </Link>
      ))}
    </section>
  );
};

export default Linktree;
